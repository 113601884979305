/**
 *
 * This is the Default WP page template
 *
 */

import React from 'react';
import Seo from "../components/seo";
import { graphql } from 'gatsby';
import ASScroll from '../components/ASScroll/ASScroll';
import CallToAction from '../modules/CallToAction/CallToAction';

const WpPageTemplate = ({ data: { wpPage } }) => {

  const { title, content, seo } = wpPage;

  const ctaText = `<h2 style="text-align: center;">Let's make magic together</h2>
<h2 style="text-align: center;"><a href="#start-a-project">Start a project</a></h2>`;

  return (
    <>
        <ASScroll />
        <Seo seo={seo} />
        <main className={`page page--content`} asscroll-container="true">
          <section asscroll="true">

            <div className="page-content">
              <h6>{title}</h6>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </section>

          <CallToAction backgroundColour="charcoal" text={ctaText} />
          </main>
      </>
  );

};

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      seo {
        canonical
        cornerstone
        metaKeywords
        metaDesc
        metaRobotsNoindex
        title
      }
    }
  }
`;

export default WpPageTemplate;